document.addEventListener('DOMContentLoaded', () => {

  //連打防止用時刻
  let lastPostTime = 0;

  const changeFavorite = async event => {
    //対象 DOM でなければ何もしない
    if (
      event === null ||
      typeof event !== 'object' ||
      ! (event.target instanceof HTMLInputElement) ||
      event.target.type !== 'checkbox' ||
      ! ('id' in event.target.dataset)
    ) {
      return;
    }

    //この時点のチェック状況を保持しておく
    const checked = event.target.checked;

    //現在時刻を用意
    const now = (new Date()).getTime();

    //最後に POST してから 1.5 秒以上経っていなければチェックの切り替えを無効化する
    if ((lastPostTime + 1500) > now) {
      //チェック内容を反転させる
      event.target.checked = ! checked;

      //処理中断
      return;
    }

    //現在時刻を更新
    lastPostTime = now;

    //サーバーと通信を試みる
    try {
      //サーバーと適切なメソッドで通知を行う
      const response = await fetch(checked ? siteUrl+'/voice/set-favorite/'+event.target.dataset.id : siteUrl+'/voice/delete-favorite/'+event.target.dataset.id, {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        }
      });

      //レスポンス異常発生
      if ( ! response.ok) {
        //もし 404 だったらログインしていない
        if (response.status === 404) {
          throw new Error('ログインセッションが切れている可能性があります。恐れ入りますがページをリロードして再度ログインしてください。');
        }

        //エラーメッセージが返っているか判定
        const parameter = await response.json();

        //もしエラーメッセージが存在すれば返す
        if ('messages' in parameter && Array.isArray(parameter.messages)) {
          throw parameter.messages;
        }

        //エラーメッセージを文字列に変換して例外を投げる
        throw new Error('エラーが発生しました。しばらくしてから再度お試しください。');
      }
    }

      //エラーが起きたら文字列の一次元配列に成形して errors に登録する
    catch (error) {
      //恐らく通信途絶
      if (error instanceof TypeError) {
        alert('ネットワーク接続に失敗しました。');
      }

      //恐らく意図的な中断
      else if (error instanceof DOMException) {
        alert('ネットワーク接続が中断されました。');
      }

      //恐らくサーバーからのメッセージ
      else if (Array.isArray(error)) {
        alert(error.join("\n"));
      }

      //恐らくカスタムエラー
      else if (error instanceof Error) {
        alert(error.message);
      }

      //不明
      else {
        alert('エラーが発生しました。しばらくしてから再度お試しください。');
      }

      //チェック内容を反転させる
      event.target.checked = ! checked;
    }
  };

  //対象となる保存ボタン
  const favorites = document.getElementsByClassName('js-favorite');

  //ボタンごとにトグルイベントを用意
  for (let i = 0, max = favorites.length; i < max; i = (i + 1) | 0) {
    favorites.item(i).addEventListener('change', changeFavorite);
  }

});
